<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import clientDetails from "@/components/clients/clientDetails.vue";

/**
 * Starter component
 */
export default {
    page: {
        title: "Member Details",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, clientDetails },
    data() {
        return {
            title: "Member Details",
            items: [
                {
                    text: "Member Details",
                    active: true,
                },
            ],
        };
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <clientDetails />
    </Layout>
</template>
